import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Blog from './components/home/blog';
import Header from './components/home/header';
import CarousalComponent from '../components/carousal-component';
import { ClientData, PartnersData } from '../data/carousal-db';
import AccordionComponent from './components/careers/accordionComponent';
import WhyInfenox from './components/careers/whyInfenox';

export default function Careers() {
  return (
    <Layout>
      <Seo title="Home" />
      <section>
        <Header />
        <WhyInfenox />
        <AccordionComponent />
        <Blog />
        <CarousalComponent Data={PartnersData} />
        <CarousalComponent Data={ClientData} />
      </section>
    </Layout>
  );
}
